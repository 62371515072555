import { Amplify } from "aws-amplify";

import "./App.css";
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { Toaster } from "react-hot-toast";
import Toast from "./components/Toast";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import amplifyconfiguration from "./aws-exports";
import Topbar from "./components/Topbar";
import { IS_LOCAL, STAGE } from "./lib/constants";
import { getClient } from "./lib/automatic";

// Mutilate the amplify redirect urls and domain - don't worry just mutate 🤪
amplifyconfiguration.oauth.redirectSignIn = IS_LOCAL
  ? "http://localhost:3002/"
  : `https://dashboard${STAGE === "prod" ? "" : `.${STAGE}`}.axle.insure/`;
amplifyconfiguration.oauth.redirectSignOut = IS_LOCAL
  ? "http://localhost:3002/"
  : `https://dashboard${STAGE === "prod" ? "" : `.${STAGE}`}.axle.insure/`;
amplifyconfiguration.oauth.domain = "auth.axle.insure";

// Configure Amplify
Amplify.configure(amplifyconfiguration);

// Create context for the client
export const ClientContext = React.createContext({});

const Home = () => {
  const [clientConfig, setClientConfig] = useState([]);
  // Get client config on home page load
  useEffect(() => {
    getClient(true, "validation").then((data) => {
      setClientConfig(data);
    });
  }, []);
  return (
    <>
      <ClientContext.Provider value={clientConfig}>
        <div className="flex h-full w-full">
          <Sidebar />
          <div className="flex flex-1 flex-col ml-52 relative">
            <Topbar />
            <Outlet />
            <Toaster position="bottom-right">{(t) => <Toast t={t} />}</Toaster>
          </div>
        </div>
      </ClientContext.Provider>
    </>
  );
};

const Login = () => (
  <div className="flex items-center justify-center h-screen">
    <Authenticator
      loginMechanisms={["email"]}
      socialProviders={["google"]}
      hideSignUp={true}
    />
  </div>
);

function App() {
  const { route } = useAuthenticator((context) => [context.route]);
  return route === "authenticated" ? <Home /> : <Login />;
}

export default function AppWithProvider() {
  return (
    <Authenticator.Provider>
      <App></App>
    </Authenticator.Provider>
  );
}
