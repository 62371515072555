import { getAccessJwtToken } from "../lib/auth";
import { trackPromise } from "react-promise-tracker";
import _, { isEmpty } from "lodash";
import queryString from "query-string";

const local = false;
const baseUrl = local
  ? "http://localhost:4000/dev"
  : `https://${
      process.env.REACT_APP_STAGE === "prod"
        ? "api"
        : process.env.REACT_APP_STAGE
    }.axle.insure/automatic`;

/**
 * It creates a new ignition session for a user
 * @param userId - The user's id
 */
export const createIgnitionSession = async (userId) => {
  try {
    const res = await trackPromise(
      fetch(`${baseUrl}/users/${userId}/ignition`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
      })
    );

    const body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes an ignition token, sends it to the server, and returns the session data
 * @param ignitionToken - The token that was generated when the ignition session was created.
 * @returns The ignition session is being returned.
 */
export const getIgnitionSession = async (ignitionToken) => {
  try {
    const res = await trackPromise(
      fetch(`${baseUrl}/ignition/${ignitionToken}`, {
        method: "get",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
      })
    );

    const body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * It fetches all the ignition sessions from the database, and returns them in an array
 * @returns An array of ignition sessions.
 */
export const getIgnitionSessions = async ({ start, end }) => {
  let currentLastEvaluatedKey;
  let allIgnitionSessions = [];

  try {
    do {
      const response = await fetch(
        `${baseUrl}/ignition?${queryString.stringify({
          start,
          end,
          lastEvaluatedKey: currentLastEvaluatedKey,
        })}`,
        {
          method: "get",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAccessJwtToken(),
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const { ignitionSessions, lastEvaluatedKey } = await response.json();

      currentLastEvaluatedKey = lastEvaluatedKey;
      allIgnitionSessions = [...allIgnitionSessions, ...ignitionSessions];
    } while (currentLastEvaluatedKey);

    const sortedIgnitionSessions = allIgnitionSessions.sort((a, b) => {
      return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
    });

    return sortedIgnitionSessions;
  } catch (error) {
    throw error;
  }
};

/**
 * It creates a user
 * @param user - The user object that we want to create.
 * @returns The user object
 */
export const createUser = async (user) => {
  try {
    const res = await trackPromise(
      fetch(`${baseUrl}/users`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
        body: JSON.stringify(user),
      })
    );

    const body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * It fetches a user from the backend and returns the user object
 * @param userId - The user's id
 * @returns The user object
 */
export const getUser = async (userId) => {
  try {
    const res = await trackPromise(
      fetch(`${baseUrl}/users/${userId}`, {
        method: "get",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
      })
    );

    const body = await res.json();
    // console.log(data);
    if (!res.ok) {
      throw Error(body.message);
    }
    return body.data;
  } catch (error) {
    throw error;
  }
};

/**
 * It fetches all users from the database, one page at a time, until there are no more pages to fetch
 * @returns An array of users.
 */
export const getUsers = async () => {
  let currentLastEvaluatedKey;
  let allUsers = [];

  try {
    do {
      // const encodedValue = encodeURIComponent(
      //   JSON.stringify(currentLastEvaluatedKey)
      // );
      // const currentLastEvaluatedKeyParameter = `lastEvaluatedKey=${encodedValue}`;

      const response = await trackPromise(
        fetch(
          `${baseUrl}/users?${queryString.stringify({
            lastEvaluatedKey: currentLastEvaluatedKey,
          })}`,
          {
            method: "get",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: await getAccessJwtToken(),
            },
          }
        )
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const { users, lastEvaluatedKey } = await response.json();

      currentLastEvaluatedKey = lastEvaluatedKey;
      allUsers = [...allUsers, ...users];
    } while (currentLastEvaluatedKey);

    const sortedUsers = allUsers.sort((a, b) => {
      return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
    });

    return sortedUsers;
  } catch (error) {
    throw error;
  }
};

/**
 * It fetches all carriers from the database
 * @returns An array of carriers.
 */
export const getCarriers = async () => {
  try {
    const response = await fetch(`${baseUrl}/carriers`, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAccessJwtToken(),
      },
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const carriers = await response.json();

    // Custom comparator function to decrease rank of undefined
    const shareComparator = (item) => {
      if (item.share === undefined) {
        // Assign a low value to items with undefined share
        return Number.MIN_SAFE_INTEGER;
      }
      return item.share;
    };

    // Sort carriers by status, rank, and share
    const sortedCarriers = _(carriers).orderBy(
      ["status", "rank", shareComparator],
      ["asc", "asc", "desc"]
    );

    return sortedCarriers;
  } catch (error) {
    throw error;
  }
};

/**
 * It fetches all policies from the API, sorts them by date, and returns them
 * @returns An array of policies
 */
export const getPolicies = async () => {
  let currentLastEvaluatedKey;
  let allPolicies = [];

  try {
    do {
      const response = await fetch(
        `${baseUrl}/policies?${queryString.stringify({
          lastEvaluatedKey: currentLastEvaluatedKey,
          expand: true,
        })}`,
        {
          method: "get",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAccessJwtToken(),
          },
        }
      );
      if (!response.ok) {
        throw Error(response.statusText);
      }

      const { policies, lastEvaluatedKey } = await response.json();

      currentLastEvaluatedKey = lastEvaluatedKey;
      allPolicies = [...allPolicies, ...policies];
    } while (currentLastEvaluatedKey);

    const sortedPolicies = allPolicies.sort((a, b) => {
      return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
    });

    return sortedPolicies;
  } catch (error) {
    throw error;
  }
};

/**
 * It fetches a policy from the API and returns the policy data
 * @param policyId - The id of the policy you want to get.
 * @param options -  supports "refresh".
 * @returns The policy object
 */
export const getPolicy = async (policyId, options = {}) => {
  try {
    const res = await trackPromise(
      fetch(
        `${baseUrl}/policies/${policyId}?${new URLSearchParams({
          ...options,
        })}`,
        {
          method: "get",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAccessJwtToken(),
          },
        }
      )
    );

    const data = await (await res.json()).data;

    if (!res.ok) {
      throw Error(data.message);
    }
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

export const validatePolicy = async (policy) => {
  try {
    const res = await trackPromise(
      fetch(`${baseUrl}/policies/${policy}/validate`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
      })
    );

    const data = await (await res.json()).data;

    if (!res.ok) {
      throw Error(data.message);
    }
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It fetches an account from the API and returns the data
 * @param accountId - The account id of the account you want to get.
 * @param options -  supports "refresh".
 * @returns An object with the following properties:
 */
export const getAccount = async (accountId, options) => {
  try {
    const res = await trackPromise(
      fetch(
        `${baseUrl}/accounts/${accountId}?${new URLSearchParams({
          ...options,
        })}`,
        {
          method: "get",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAccessJwtToken(),
          },
        }
      )
    );

    const data = await (await res.json()).data;
    // console.log(data);
    if (!res.ok) {
      throw Error(data.message);
    }
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It fetches the client data from the server and returns it
 * @returns The client object
 */
export const getClient = async (config, types) => {
  try {
    const res = await trackPromise(
      fetch(
        `${local ? "http://localhost:4000/dev" : baseUrl}/clients?` +
          new URLSearchParams({
            config,
            types,
          }),
        {
          method: "get",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: await getAccessJwtToken(),
          },
        }
      )
    );

    const data = await (await res.json()).data;
    // console.log(data);
    if (!res.ok) {
      throw Error(data.message);
    }
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes in a params object, and returns a signed url for uploading a file to S3
 * @param params - {
 * @returns A signed URL
 */
export const generateSignedUrl = async (params) => {
  try {
    const res = await trackPromise(
      fetch(`${local ? "http://localhost:4000/dev" : baseUrl}/documents`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
        body: JSON.stringify(params),
      })
    );

    const data = await res.json();

    if (!res.ok) {
      throw Error(data.message);
    }
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 *
 * @param {*} carrier
 * @param {*} state
 * @returns A list of policy forms for the given carrier and state
 */
export const getPolicyForms = async (carrier, state) => {
  try {
    const res = await trackPromise(
      fetch(`${baseUrl}/forms?carrier=${carrier}&state=${state}`, {
        method: "get",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAccessJwtToken(),
        },
      })
    );
    const data = await res.json();

    if (!res.ok) {
      throw Error(data.message);
    }

    return data.data;
  } catch (error) {
    throw Error(error.message);
  }
};
