import _, { get, toLower, upperFirst } from "lodash";
import { Link } from "react-router-dom";

import ArrowRight from "../icons/ArrowRight";
import Icon from "./Icon";

const carriersWithLongImages = [
  "state-farm",
  "progressive",
  "allstate",
  "geico",
  "liberty-mutual",
  "farmers",
];

const generatePolicyLabel = (policy) => {
  try {
    if (get(policy, "properties")) {
      switch (_(policy).get("properties[0].type")) {
        case "vehicle":
          return (
            [
              _(policy).get("properties[0].data.year"),
              _(policy).get("properties[0].data.make"),
              _(policy).get("properties[0].data.model"),
              policy.properties.length > 1 && "+ Others",
            ]
              // Filter out null values
              .filter((i) => i)
              // Join label together
              .join(" ")
          );
        case "dwelling":
          return (
            [
              _(policy).get("properties[0].data.addressLine1"),
              _(policy).get("properties[0].data.addressLine2"),
              policy.properties.length > 1 && "+ Others",
            ]
              // Filter out null values
              .filter((i) => i)
              // Join label together
              .join(" ")
          );
        default:
          return "—";
      }
    }
    return "—";
  } catch (error) {
    return "—";
  }
};

const PolicyCard = ({ policy }) => {
  // Insured w/ type "primary" or "primary named insured"
  const policyholder = _(policy)
    .chain()
    .get("insureds")
    .find((insured) =>
      ["primary", "primary named insured"].includes(toLower(insured.type))
    )
    .thru(
      (insured) =>
        insured &&
        [insured.firstName, insured.lastName].filter((i) => i).join(" ")
    )
    .value();

  return (
    <Link to={`/policies/${policy.id}`} className="flex flex-col gap-y-3">
      <div className="flex flex-col gap-y-4 p-4 bg-gray-50 rounded-lg w-72 h-44 relative overflow-hidden shadow hover:scale-[1.025]">
        {/* Policy header */}
        <div className="flex flex-row justify-between items-center">
          {/* Carrier logo */}
          {carriersWithLongImages.includes(policy.carrier) ? (
            <img
              className="h-3"
              src={`https://axle-labs-assets.s3.amazonaws.com/carrierLogos/long/${policy.carrier}.svg`}
            />
          ) : (
            <p>
              {_(policy.carrier).chain().replace("-", " ").startCase().value()}
            </p>
          )}

          {/* Policy type */}
          <p>{upperFirst(policy.type)}</p>
        </div>
        {/* Policy details */}
        <div className="flex flex-col gap-y-2">
          {/* Policy label - first property + others */}
          <p className="text-sm font-semibold truncate">
            {generatePolicyLabel(policy)}
          </p>

          {/* Policyholder */}
          {<p className="text-sm">{policyholder}</p>}

          {/* Policy number and expiration date */}
          <div className="flex flex-row gap-x-2">
            {/* Policy Number */}
            <div className="flex flex-row gap-x-1 items-center">
              <p className="text-[0.5rem] uppercase">Policy</p>
              <p className="text-xs">{policy.policyNumber}</p>
            </div>

            {/* Expiration date */}
            <div className="flex flex-row gap-x-1 items-center">
              <p className="text-[0.5rem] uppercase">Exp</p>
              <p className="text-xs">
                {new Date(policy.expirationDate).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }) ?? "—"}
              </p>
            </div>
          </div>

          {/* Coverages */}
          <div className="flex flex-row gap-x-1 items-center">
            <p className="text-[0.5rem] uppercase">Coverages</p>
            <p className="text-xs">
              {_(policy)
                .chain()
                .get("coverages")
                .map((coverage) => coverage.code)
                .filter((i) => i)
                .join(" ")
                .value()}
            </p>
          </div>
        </div>

        {/* Decorative carrier image */}
        {carriersWithLongImages.includes(policy.carrier) && (
          <img
            className="absolute -left-10 top-full -translate-y-[80%] w-[175%] max-w-none opacity-[0.04]"
            src={`https://axle-labs-assets.s3.amazonaws.com/carrierLogos/long/${policy.carrier}.svg`}
          />
        )}
      </div>
      <div className="flex flex-row justify-between items-center hover:scale-[1.025]">
        <p className="text-sm">{`${policyholder ?? "User"}'s ${
          policy.type ?? ""
        } policy`}</p>
        <Icon icon={ArrowRight} />
      </div>
    </Link>
  );
};

export default PolicyCard;
